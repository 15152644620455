import Splitting from "splitting";

import { $$ } from "@utils/dom";
import { mobile } from "@utils/mobile";


export default () => {
  [ ...$$(mobile ? '[data-splitting]:not(.btn__label)' : '[data-splitting]') ].forEach(el => {
    const splittingMethod = el.dataset.splitting || "chars";

    Splitting({
      target: el,
      by: splittingMethod
    });
  });
}
